:root {
    --overlay-icon-text-animation-speed: 1s;
}

.overlay-icon-text__background {
    position: absolute;
    width: 100%;
    height: 100%;
}

.overlay-icon-text__element__container {
    display: flex;
    position: relative;
    height: 100px;
    align-items: center;
    transform-origin: center;
}

.overlay-icon-text__element__element {
    position: relative;
}

.overlay-icon-text__element__blur {
    position: absolute;
    filter: blur(5px);
    -webkit-filter: blur(5px);
    z-index: -1;
}

.overlay-icon-text__container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    overflow: hidden;
}

.overlay-icon-text__container.disabled {
    height: 0;
}

.overlay-icon-text__container.enabled {
    height: 100px;
}

.overlay-icon-text__container.animation-disappear {
    height: 100px;
    #icon {
        animation: icon-animation var(--overlay-icon-text-animation-speed) ease-in-out reverse forwards;
    }
    #text {
        animation: text-animation var(--overlay-icon-text-animation-speed) ease-in-out reverse forwards;
    }
}

.overlay-icon-text__container.animation-appear {
    height: 100px;
    #icon {
        animation: icon-animation var(--overlay-icon-text-animation-speed) ease-in-out forwards;
    }
    #text {
        animation: text-animation var(--overlay-icon-text-animation-speed) ease-in-out forwards;
    }
}

.overlay-icon-text__element__container#icon {
    overflow: hidden;
    width: 700px;
}
.overlay-icon-text__element__container#text {
    margin-left: 15px;
    font-size: 60px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    top: -5px
}

@keyframes icon-animation {
    0% {
        transform: translateX(600px) scale(0);
        width: 100px;
        opacity: 0;
    }
    33% {
        transform: translateX(600px) scale(1);
        width: 100px;
        opacity: 1;
    }
    34% {
        width: 700px;
    }
    100% {
        transform: translateX(0px) scale(1);
        width: 700px;
    }
}


@keyframes text-animation {
    0% {
        transform: translateX(-600px)
    }
    33% {
        transform: translateX(-600px)
    }
    100% {
        transform: translateX(0px)
    }
}

.upheaval {
    font-family: 'Upheaval';
    font-size: 70px !important;
}