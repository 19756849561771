.game__background__container {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0px;
    left: 0px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
}

.bomber-stars__background {
    height: calc(200vh + 2000px);
    aspect-ratio: 1;
    position: absolute;
    animation: background-rotate 25s linear infinite;
    align-self: center;
    top: -1000px;
}

.bomber-stars__background__color {
    height: calc(200vh + 2000px);
    aspect-ratio: 1;
    position: absolute;
    align-self: center;
    top: -1000px;
    background-color: #00244690;
}

@keyframes background-rotate {
    from {
      transform: rotateZ(0);
    }
  
    to {
      transform: rotateZ(180deg);
    }
}

.game__container {
    position: relative;
    z-index: 1;
}


.mooving-stellar-vr__background {
    min-height: 100vh;
    min-width: 100vw;
    position: absolute;
    align-self: center;
}

.mooving-stellar-vr__background__color {
    height: calc(200vh + 1000px);
    position: absolute;
    align-self: center;
    top: -500px;
    background-color: #00244690;
}