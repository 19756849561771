.crocodyle__title {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold !important;
    padding: 20px 0;
    gap: 10px;
}

.crocodyle__label {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold !important;
    font-size: 20px !important;
    gap: 5px;
}

.crocodyle__text {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: normal !important;
    font-size: 18px !important;
    gap: 5px;
}

.crocodyle__italic {
    font-style: italic !important;
}

.crocodyle__input {
    margin: 5px 0 !important;
    width: 100%;
}

.crocodyle__form {
    display: flex;
    flex-direction: column;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.crocodyle__connect-four__container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 20px;
    padding: 20px;
    width: 100%;
    height: 100%;
    flex: 1;
    align-self: center;
}

.crocodyle__row {
    display: flex;
    flex-direction: row;
    gap: 10px;
    width: 100%;
}

.crocodyle__player-selected {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    gap: 10px;
}

.crocodyle__player-list {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    align-self: flex-start;
}

.crocodyle__list__reverse {
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
    align-items: flex-start;
    font-weight: normal !important;
    padding-top: 10px;
}

.crocodyle__answer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
}