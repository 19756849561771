.app.offset-header {
    min-height: calc(100vh - 50px);
}
.app {
    min-height: calc(100vh);
    display: flex;
    justify-content: center;
}

.swordsouler__title {
    text-align: center;
    padding-bottom: 20px;
}

.swordsouler__row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.swordsouler__column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
    width: 100%;
}