.overlay__chat__container {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    height: calc(100vh - 50px);
    width: 700px;
    align-self: flex-end;
    justify-self: flex-start;
    padding: 20px;
    padding-top: 30px;
    overflow: hidden;
}

.overlay__chat__message {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background-color: lighten($color: pink, $amount: 10%);
    border: 1px solid black;
    border-radius: 30px;
    animation: overlay__chat__message-animation 1s ease-in-out, overlay__chat__message-animation-disappear 25s ease-in-out forwards;
    padding: 20px;
    padding-top: 35px;
    margin-top: 50px;

    /* These are technically the same, but use both */
    overflow-wrap: break-word;
    word-wrap: break-word;

    -ms-word-break: break-all;
    /* This is the dangerous one in WebKit, as it breaks things wherever */
    word-break: break-all;
    /* Instead use this non-standard one: */
    word-break: break-word;

    /* Adds a hyphen where the word breaks, if supported (No Blink) */
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
}

.overlay__chat__message-elements {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    gap: 0px;
    overflow: hidden;
}

.overlay__chat__message-element {
    padding-top: 0px;
}

.overlay__chat__user {
    position: relative;
    display: inline-flex;
    background-color: #f19ae9;
    border-radius: 100px;
    padding: 10px 18px;
    height: 36px;
    font-size: 25px;
    top: -63px;
    left: -30px;
    line-height: 36px;
    color: white;
    justify-content: center;
    align-items: center;
    gap: 5px;
    font-weight: bold;
    animation: overlay__chat__user-animation 1s ease-in-out;
}

.overlay__chat__badge {
    width: 36px;
    height: 36px;
    border-radius: 5px;
}

.overlay__chat__emote {
    width: 50px;
    height: 50px;
    object-fit: contain;
    margin: 0 10px;
}

.overlay__chat__text {
    font-size: 25px;
}

@keyframes overlay__chat__message-animation {
    0% {
        padding-top: 0;
        padding-bottom: 0;
        max-width: 20px;
        max-height: 0px;
        margin-top: 0px;
    }
    25% {
        padding-top: 15px;
        padding-bottom: 0;
        max-width: 700px;
        max-height: 0px;
        margin-top: 50px;
    }
    100% {
        padding-top: 35px;
        padding-bottom: 20px;
        max-width: 700px;
        max-height: 500px;
        margin-top: 50px;
    }
}

@keyframes overlay__chat__user-animation {
    0% {
        opacity: 0;
    }
    25% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes overlay__chat__message-animation-disappear {
    95% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}