body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.no-select {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.offset-header {
    padding-top: 50px;
}

.row {
    display: flex;
    flex-wrap: wrap;
}

.column {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
}

.center-horizontal {
    display: flex;
    justify-content: center;
}

.center-vertical {
    display: flex;
    align-items: center;
}

.start-vertical {
    display: flex;
    align-items: flex-start;
}

.no-decoration {
    text-decoration: none;
}

#header__menu > div.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiPaper-root.MuiMenu-paper.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPopover-paper.css-oqt7a2-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper {
    background-color: transparent; //remove background color of paper menu element (showing generally on border)
}

.underline {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.underline::after {
    content: '';
    position: absolute;
    display: block;
    width: 50%;
    height: 2px;
    background-color: #fff;
    transition: width 0.3s;
    align-self: center;
    justify-self: center;
    top: 40px
}

.reverse-horizontally {
    transform: rotateY(180deg);
}

@font-face {
    font-family: "Upheaval";
    src: url("assets/fonts/Upheaval.otf");
  }