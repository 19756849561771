.game-presentation__title {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    margin-bottom: 20px;
    margin-top: 10px;
    img {
        width: 50px;
        height: 50px;
        margin: 0 20px;
    }
    h3 {
        text-align: center;
    }
}

.game-presentation {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 20px;
}

.game-presentation__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 20px;
    max-width: 800px;
    gap: 10px;
    iframe {
        width: 100%;
        aspect-ratio: 16/9;
        border: 1px solid #ffff;
        border-radius: 8px;
    }
    span {
        flex: 1;
        text-align: justify;
        font-size: 16px;
        .paragraph {
            margin-bottom: 10px;
            margin-top: 0;
            text-indent: 10px;
        }
    }
    a {
        width: 100%;
    }
}

@media (min-width: 900px) {
    .game-presentation__content {
        max-width: inherit;
        iframe {
            flex: 1;
            width: inherit;
        }
        div {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: center;
            height: 100%;
            flex: 1;
            gap: 10px;
        }
        p {
            flex: 1;
            align-self: center;
        }
    }
}