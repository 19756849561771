.falling-token-image {

    position: relative;
    animation: 5s linear 0s token-animation-falling;
    margin-top: 0;
    z-index: 0;
}

.falling-token {

    margin-top: calc(-50px * 1.18);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 0;
}

.token {
    position: absolute;
    width: 50px;
    height: 50px;
    z-index: 5;
}

.team-icon {
    width: 50px;
    height: 50px;
    padding-top: 10px;
    z-index: 5;
}

.token-big {
    width: 100px;
    height: 100px;
    padding-top: 10px;
    z-index: 5;
}

.token-little {
    width: 20px;
    height: 20px;
    z-index: 5;
}

.vote-text {
    color: var(--crocodyle-green-dark);
    font-size: 20px;
}

.vote {
    display: flex;
    align-items: center;
    justify-content: center;
}

.tile {
    width: calc(50px * 1.18);
    height: calc(50px * 1.18);
    aspect-ratio: 1;
    z-index: 10;
}

.animation {
    animation: 3s linear 1s infinite alternate token-animation;
}

.in-tile {
    position: absolute;
    padding-top: calc(50px * 0.09);
    padding-left: calc(50px * 0.09);
}

.token-win {
    position: absolute;
    width: calc(50px * 1.18);
    height: calc(50px * 1.18);
    z-index: 15;
    padding-top: 0;
    padding-left: 0;
}

.side {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
}

.text {
    color: var(--crocodyle-green-dark);
    font-size: 20px;
    font-weight: bold;
    text-align: center;
}

.button:hover {
    border-color: #FFFFFF;
    color: #FFFFFF;
}

@keyframes token-animation {
    from {
        width: 50px;
    }
    to {
        width: 100%;
    }
}

@keyframes token-animation-falling {
    from {
        margin-top: 0;
    }
    to {
        margin-top: calc(50px * 1.18*6);
    }
}